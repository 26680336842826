<template>
  <div>
    <h3>Instalacje</h3>
    <div class="row bg-light mt-1 ml-0">
      <div class="col-6">
        <router-link to="/">
          <button class="btn btn-info mt-1 mb-1 float-left">
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="bi bi-calendar-date"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"
              />
              <path
                d="M6.445 11.688V6.354h-.633A12.6 12.6 0 0 0 4.5 7.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61h.675zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82h-.684zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23z"
              />
            </svg>
            <br />
            <!-- Dodaj -->
          </button>
        </router-link>
        <router-link to="/eventlist">
          <button class="btn btn-info ml-1 mt-1 mb-1 float-left">
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="bi bi-list-ol"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5z"
              />
              <path
                d="M1.713 11.865v-.474H2c.217 0 .363-.137.363-.317 0-.185-.158-.31-.361-.31-.223 0-.367.152-.373.31h-.59c.016-.467.373-.787.986-.787.588-.002.954.291.957.703a.595.595 0 0 1-.492.594v.033a.615.615 0 0 1 .569.631c.003.533-.502.8-1.051.8-.656 0-1-.37-1.008-.794h.582c.008.178.186.306.422.309.254 0 .424-.145.422-.35-.002-.195-.155-.348-.414-.348h-.3zm-.004-4.699h-.604v-.035c0-.408.295-.844.958-.844.583 0 .96.326.96.756 0 .389-.257.617-.476.848l-.537.572v.03h1.054V9H1.143v-.395l.957-.99c.138-.142.293-.304.293-.508 0-.18-.147-.32-.342-.32a.33.33 0 0 0-.342.338v.041zM2.564 5h-.635V2.924h-.031l-.598.42v-.567l.629-.443h.635V5z"
              />
            </svg>
            <br />
            <!-- Dodaj -->
          </button>
        </router-link>
        <router-link to="/addinstallation">
          <button class="btn btn-info ml-1 mt-1 mb-1 float-left">
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="bi bi-folder-plus"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M9.828 4H2.19a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91H9v1H2.826a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3h3.982a2 2 0 0 1 1.992 2.181L15.546 8H14.54l.265-2.91A1 1 0 0 0 13.81 4H9.828zm-2.95-1.707L7.587 3H2.19c-.24 0-.47.042-.684.12L1.5 2.98a1 1 0 0 1 1-.98h3.672a1 1 0 0 1 .707.293z"
              />
              <path
                fill-rule="evenodd"
                d="M13.5 10a.5.5 0 0 1 .5.5V12h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V13h-1.5a.5.5 0 0 1 0-1H13v-1.5a.5.5 0 0 1 .5-.5z"
              />
            </svg>
            <br />
            <!-- Dodaj -->
          </button>
        </router-link>
        <button @click="getData()" class="btn btn-warning ml-1 mt-1 mb-1 float-left">
          Pobierz Instalacje
        </button>
      </div>
      <div class="col-sm-12 align-center col-md-6">
        <input
          v-model="search"
          class="form-control w-50 form-control-sm mt-1 mr-3"
          type="search"
          placeholder="Wyszukaj instalacje"
          aria-label="Search"
        />
      </div>
      <!-- <div class="col-6">
        <input
          v-model="search"
          class="form-control w-50 float-right form-control-sm mt-1 mr-3"
          type="search"
          placeholder="Wyszukaj instalacje"
          aria-label="Search"
        />
      </div> -->
    </div>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Typ</th>
          <th scope="col">Nazwa</th>
          <th scope="col" @click="sortbyDate()">Data instalacji</th>
          <th scope="col">Data serwisu</th>
          <th scope="col">Numer telefonu</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          scope="row"
          v-for="installation in filteredInstalations"
          :key="installation.id"
          :class="installation.contractor.type"
        >
          <th scope="row">
            <div v-if="installation.contractor.type == 'company'">
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                class="bi bi-building"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694L1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"
                />
                <path
                  d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"
                />
              </svg>
            </div>
            <div v-else>
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                class="bi bi-person"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0zM8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6 5c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"
                />
              </svg>
            </div>
          </th>
          <td>{{ installation.contractor.label }}</td>
          <td>{{ installation.date }}</td>
          <td>{{ installation.service }}</td>
          <td>{{ installation.contractor.phoneNumber }}</td>

          <td>
            <button
              class="btn btn-sm align-middle btn-info"
              @click="openInstallationModal(installation)"
            >
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                class="bi bi-file-earmark-arrow-up"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 0h5.5v1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h1V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"
                />
                <path d="M9.5 3V0L14 4.5h-3A1.5 1.5 0 0 1 9.5 3z" />
                <path
                  fill-rule="evenodd"
                  d="M8 12a.5.5 0 0 0 .5-.5V7.707l1.146 1.147a.5.5 0 0 0 .708-.708l-2-2a.5.5 0 0 0-.708 0l-2 2a.5.5 0 1 0 .708.708L7.5 7.707V11.5a.5.5 0 0 0 .5.5z"
                />
              </svg>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <test-modal></test-modal>
    <installation-modal-list
      @closeModal="closeModal()"
      :installation="installation"
    ></installation-modal-list>
  </div>
</template>
<script>
import { mapState } from "vuex";
import InstallationModalList from "../components/InstallationModalList";
import TestModal from "../components/TestModal";
export default {
  data() {
    return {
      installation: "",
      search: "",
    };
  },
  components: {
    "installation-modal-list": InstallationModalList,
    "test-modal": TestModal,
  },
  methods: {
    openTestModal() {
      this.$modal.show("test-modal");
    },
    closeModal() {
      console.log("closemodal");
      this.$modal.hide("installation-modal-list");
    },
    openInstallationModal(item) {
      console.log(item);
      this.installation = item;
      this.$modal.show("installation-modal-list");

      // Prevent navigating to narrower view (default vue-cal behavior).
      // e.stopPropagation();
    },
    getData() {
      this.$store.dispatch("getInstallations");
    },
    sortbyDate() {},
  },
  computed: {
    ...mapState(["userProfile", "installations"]),
    filteredInstalations() {
      return this.installations.filter((item) => {
        if (item.contractor.label.toUpperCase().includes(this.search.toUpperCase())) {
          return item;
        } else if (
          item.contractor.phoneNumber.toUpperCase().includes(this.search.toUpperCase())
        ) {
          return item;
        } else if (
          item.contractor.address.toUpperCase().includes(this.search.toUpperCase())
        ) {
          return item;
        } else if (
          item.contractor.email.toUpperCase().includes(this.search.toUpperCase())
        ) {
          return item;
        }
      });
    },
  },
};
</script>
<style scoped>
.customer {
  background-color: rgba(0, 123, 255, 0.1);
}
.company {
  background-color: rgba(23, 162, 184, 0.1);
}
</style>
